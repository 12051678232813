export default [
  {
    title: "1º BACHILLER",
    horizontal: true,
    data: [
      {
        key: "1",
        text: "MATEMÁTICAS I",
        uri: "/imagenes/apuntes/MatemáticasI.jpg",
        link:
          "https://pitercios.es/apuntes-matematicas-i"
      },
      {
        key: "2",
        text: "MATEMÁTICAS CCSS I",
        uri: "/imagenes/apuntes/MatemáticasCCSSI.jpg",
        link:
          "https://pitercios.es/apuntes-matematicas-ccss-i/"
      },

      {
        key: "3",
        text: "FÍSICA I",
        uri: "/imagenes/apuntes/FísicaI.jpg",
        link:
          "https://pitercios.es/apuntes-fisica-i/"
      },
      {
        key: "4",
        text: "QUÍMICA I",
        uri: "/imagenes/apuntes/QuímicaI.jpg",
        link:
          "https://pitercios.es/apuntes-quimica-i/"
      }
    ]
  },
  {
    title: "2º BACHILLER",
    horizontal: true,
    data: [
      {
        key: "1",
        text: "MATEMÁTICAS II",
        uri: "/imagenes/apuntes/MatemáticasII.jpg",
        link:
          "https://pitercios.es/apuntes-matematicas-ii/"
      },
      {
        key: "2",
        text: "MATEMÁTICAS CCSS",
        uri: "/imagenes/apuntes/MatemáticasCCSSII.jpg",
        link:
          "https://pitercios.es/apuntes-matematicas-ccss-ii/"
      },

      {
        key: "3",
        text: "FÍSICA II",
        uri: "/imagenes/apuntes/FísicaII.jpg",
        link: "https://pitercios.es/apuntes-fisica-ii/"
      },
      {
        key: "4",
        text: "QUÍMIMCA",
        uri: "/imagenes/apuntes/QuímicaII.jpg",
        link: "https://pitercios.es/apuntes-quimica-ii/"
      }
    ]
  },
  {
    title: "SELECTIVIDAD",
    horizontal: true,
    data: [
      {
        key: "1",
        text: "MATEMÁTICAS II",
        uri:
          "/imagenes/apuntes/Selectividad Matemáticas II.jpg",
        link:
          "https://drive.google.com/drive/folders/1PAN0bZSQS5_Jj7Cl99uDtu65e1ehO7pF?usp=sharing"
      },
      {
        key: "2",
        text: "MATEMÁTICAS CCSS",
        uri:
          "/imagenes/apuntes/Selectividad Matemáticas CCSS.jpg",
        link:
          "https://drive.google.com/drive/folders/1Vn9A75hZLnimlRdfK2nYjjmuvaOw9oWU?usp=sharing"

      },

      {
        key: "3",
        text: "FÍSICA",
        uri: "/imagenes/apuntes/Selectividad Física.jpg",
        link:
        "https://drive.google.com/drive/folders/1gYIwEgnBED0BknqsmbtXr8P81AN-aywd?usp=sharing"

      },
      {
        key: "4",
        text: "QUÍMICA",
        uri:
          "/imagenes/apuntes/Selectividad Química.jpg",
        link:
          "https://drive.google.com/drive/folders/144X9JS-eQMGTkMaxeUMs5zV3BxDgVYcY?usp=sharing"

      }
    ]
  },
  {
    title: "OTROS",
    horizontal: true,
    data: [
      /*
      {
        key: "1",
        text: "MAPAS MUNDO",
        uri: "/imagenes/apuntes/MatemáticasI.jpg",
        link:
          "https://drive.google.com/drive/folders/1jSkYFk4dirRK-lBmQYVF9Ey5quPXB-VT?usp=sharing"
      },
      */
      {
        key: "2",
        text: "PYTHON",
        uri: "/imagenes/apuntes/Python.jpg",
        link:
          "https://pitercios.es/python/"
      },      
      {
        key: "3",
        text: "FORMULACIÓN QUÍMICA",
        uri: "/imagenes/apuntes/Formulacion.jpg",
        link:
          "https://pitercios.es/formulacion/"
      },
    ]
  }
];
